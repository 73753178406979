<template>
  <b-navbar toggleable="lg" id="navbar">
    <a href="https://chanellerose.com" variant="link">
      <LogoSVG/>
    </a>

    <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <router-link :to="{ name: 'Experience' }">
          <b-button id="nav-link" class="poppins" variant="link">Experience</b-button>
        </router-link>
        <router-link :to="{ name: 'About' }">
          <b-button id="nav-link" class="poppins" variant="link">About</b-button>
        </router-link>
      </b-navbar-nav> -->

      <!-- Right aligned nav items -->
      <!-- <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form>
      </b-navbar-nav>

        <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse> -->
  </b-navbar>
</template>

<script>
import LogoSVG from '@/svgs/Logo.vue';

export default {
  name: "Navbar",
  components: {
    LogoSVG
  }
}

</script>

<style lang="css">

#navbar {
  height: 100%;
  max-height: 75px;
  background-color: rgba(0,0,0,0);
  position: absolute;
  top: 0;
  z-index: 1;
}

#navbar svg {
  max-width: 250px;
  width: 100%;
}

#navbar.dark svg{
  fill: #ffffff;
  transition: all 0.3s ease-in-out;
}

#navbar.dark svg:hover {
  fill: #fc0054;
}

#nav-link {
  color: #3a3a3a;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: .8rem;
}

#navbar.dark #nav-link {
  color: #ffffff;
}

#navbar.dark #nav-link:hover, #nav-link:hover {
  color: #fc0054;
  text-transform: uppercase;
  text-decoration: none;
}

.nav-logo {
  height: 50px;
}

</style>
