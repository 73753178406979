<template>
  <b-container class="home page" fluid>
    <b-row class="banner">
      <img style='height: 100%; width: 100%; object-fit: cover' src="/17454063202111700584.jpg"/>
      <div class="title-overlay">
          <div class="title-overlay-container">
            <h1>Meeting of the Minds</h1>
            <router-link :to="{ name: 'Enter' }">
              <b-button class="title-btn">ENTER</b-button>
            </router-link>
          </div>
      </div>
    </b-row>
    <b-modal id="intro-modal" ref="introModal" size="lg" centered hide-footer>
      <p><strong>'Meeting of the Minds'</strong> considers the issues that are possibly being missed throughout our world, during this
incredibly transitional time.</p>
      <p>Many people are working to shift from where we were, to where we need to be. Let’s keep the conversations going, the shifts happening and moving forward - together.</p>
      <p>Where do we go from here? I call on you to create awareness, to inspire thought, conversation and insight
for the things being overlooked.</p>
      <p><strong>Conversation alone can be a powerful mover.</strong></p>
    </b-modal>
  </b-container>
</template>

<script>

export default {
  name: 'Home',
}

</script>

<style>

.banner {
  height: calc(100vh - 75px);
  overflow: hidden;
  padding: 15px;
  padding-top: 0px;
}

.title-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.title-overlay-container {
  transform: translateY(50%);
}

.title-overlay h1 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 4rem;
}

.title-overlay .title-btn {
  background-color: black;
  color: white;
  border-radius: 0px;
  padding: 10px 40px;
  border: 2px solid black;
  margin-top: 60px;
  transition: all 0.2s ease-in-out;
}

.title-overlay .title-btn:hover {
  background-color: rgba(0,0,0,0.4);
  color: black;
}

  /* Modal Styles */

  #intro-modal, #find-modal {
      color: #ffffff;
  }

  .modal-backdrop {
      background-color: #000000;
      opacity: 0.95;
  }

  #intro-modal .modal-content {
    background: none;
    border:none;
    font-weight: 400;
  }

  #intro-modal .modal-content strong {
    color: #fc0054;
    font-weight: 400;
  }

  #intro-modal .modal-header{
      border-bottom: none;
      padding-bottom: 0px;
      color: #fc0054;
  }

  #intro-modal .modal-header .close{
      opacity: 1;
      transition: all 0.2s ease-in-out;
      color: #ffffff;
      text-shadow: none;
  }

  #intro-modal .modal-header .close:hover{
      color: #fc0054;
  }

  #intro-modal .modal-header .modal-title{
      font-weight: 600;
  }

@media only screen and (max-width: 767px) {

  .banner {
    padding: 0px;
  }

  .title-overlay {
    padding: 30px;
  }

  .title-overlay h1 {
    font-size: 3rem;
  }

  .banner-feature {
    height: auto
  }

  .banner-container {
    align-self: baseline!important;
  }
}

</style>
