<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 860 100" xml:space="preserve">
<g>
<path d="M46.25,64.15c0.51,0,0.96,0.2,1.35,0.59l3.67,3.98c-2.03,2.52-4.53,4.45-7.5,5.79c-2.97,1.34-6.53,2.01-10.69,2.01
c-3.72,0-7.06-0.64-10.03-1.91c-2.97-1.27-5.5-3.04-7.61-5.3c-2.1-2.26-3.71-4.97-4.83-8.11c-1.12-3.14-1.68-6.57-1.68-10.29
c0-3.77,0.6-7.21,1.8-10.34c1.2-3.13,2.89-5.83,5.08-8.09c2.18-2.26,4.79-4.02,7.81-5.28c3.03-1.26,6.38-1.89,10.05-1.89
c3.65,0,6.89,0.6,9.72,1.8c2.83,1.2,5.24,2.77,7.22,4.71l-3.12,4.33c-0.19,0.28-0.42,0.52-0.71,0.73c-0.29,0.21-0.69,0.31-1.2,0.31
c-0.35,0-0.71-0.1-1.07-0.29c-0.37-0.2-0.77-0.44-1.21-0.73c-0.44-0.29-0.95-0.61-1.52-0.95c-0.58-0.35-1.25-0.66-2.01-0.95
c-0.76-0.29-1.65-0.53-2.65-0.73s-2.17-0.29-3.48-0.29c-2.24,0-4.29,0.4-6.15,1.2s-3.46,1.95-4.8,3.46
c-1.34,1.51-2.38,3.36-3.12,5.54c-0.74,2.18-1.11,4.67-1.11,7.47c0,2.82,0.4,5.33,1.2,7.52s1.88,4.04,3.24,5.54
c1.36,1.5,2.97,2.65,4.82,3.45c1.85,0.8,3.83,1.2,5.96,1.2c1.27,0,2.42-0.07,3.45-0.21c1.03-0.14,1.98-0.36,2.84-0.66
c0.87-0.3,1.69-0.69,2.46-1.16c0.77-0.47,1.54-1.06,2.3-1.75c0.23-0.21,0.47-0.38,0.73-0.5C45.71,64.21,45.97,64.15,46.25,64.15z"
/>
<path d="M102.7,25.86v50.11h-9.36v-21.9H69.81v21.9h-9.36V25.86h9.36v21.55h23.53V25.86H102.7z"/>
<path d="M159.56,75.96h-7.21c-0.81,0-1.47-0.2-1.98-0.61c-0.51-0.4-0.89-0.91-1.14-1.51l-3.74-10.22h-20.76l-3.74,10.22
c-0.19,0.53-0.54,1.02-1.07,1.46c-0.53,0.44-1.19,0.66-1.98,0.66h-7.28l19.72-50.11h9.49L159.56,75.96z M143.1,57.04L137,40.38
c-0.3-0.74-0.61-1.62-0.94-2.63c-0.32-1.02-0.65-2.11-0.97-3.29c-0.3,1.18-0.61,2.28-0.94,3.31c-0.32,1.03-0.64,1.92-0.94,2.69
l-6.06,16.6H143.1z"/>
<path d="M209.77,25.86v50.11h-4.78c-0.74,0-1.36-0.12-1.85-0.36c-0.5-0.24-0.98-0.65-1.44-1.23l-26.16-33.4
c0.14,1.52,0.21,2.93,0.21,4.23v30.77h-8.21V25.86h4.89c0.39,0,0.73,0.02,1.02,0.05c0.29,0.03,0.54,0.1,0.76,0.21
c0.22,0.1,0.43,0.25,0.64,0.45s0.44,0.46,0.69,0.78l26.27,33.54c-0.07-0.81-0.13-1.61-0.17-2.39c-0.05-0.79-0.07-1.51-0.07-2.18
V25.86H209.77z"/>
<path d="M233.05,33.27v13.9h17.53v7.17h-17.53v14.17h22.25v7.45h-31.6V25.86h31.6v7.42H233.05z"/>
<path d="M295.67,68.27v7.69h-29.35V25.86h9.32v42.41H295.67z"/>
<path d="M334.44,68.27v7.69h-29.35V25.86h9.32v42.41H334.44z"/>
<path d="M353.22,33.27v13.9h17.53v7.17h-17.53v14.17h22.25v7.45h-31.6V25.86h31.6v7.42H353.22z"/>
<path d="M445.22,75.96h-8.42c-1.59,0-2.75-0.62-3.46-1.87L422.8,58.05c-0.39-0.6-0.83-1.03-1.3-1.28c-0.47-0.25-1.17-0.38-2.1-0.38
h-3.98v19.58h-9.32V25.86h15.28c3.4,0,6.31,0.35,8.75,1.06c2.44,0.71,4.44,1.69,5.99,2.96c1.56,1.27,2.71,2.79,3.45,4.56
c0.74,1.77,1.11,3.71,1.11,5.84c0,1.69-0.25,3.28-0.75,4.78c-0.5,1.5-1.21,2.86-2.15,4.09c-0.94,1.23-2.09,2.3-3.46,3.22
c-1.37,0.92-2.94,1.65-4.7,2.18c0.6,0.35,1.16,0.76,1.66,1.23c0.51,0.47,0.97,1.03,1.39,1.68L445.22,75.96z M421.24,49.7
c1.76,0,3.29-0.22,4.59-0.66c1.31-0.44,2.38-1.04,3.22-1.82c0.84-0.77,1.47-1.69,1.89-2.75c0.42-1.06,0.62-2.23,0.62-3.5
c0-2.54-0.84-4.49-2.51-5.86c-1.68-1.36-4.23-2.04-7.68-2.04h-5.96V49.7H421.24z"/>
<path d="M500.52,50.91c0,3.67-0.61,7.08-1.82,10.21s-2.93,5.84-5.15,8.13c-2.22,2.29-4.89,4.07-8,5.35
c-3.12,1.28-6.58,1.92-10.4,1.92c-3.79,0-7.24-0.64-10.36-1.92c-3.12-1.28-5.79-3.07-8.02-5.35s-3.96-5-5.18-8.13
c-1.22-3.13-1.84-6.53-1.84-10.21s0.61-7.07,1.84-10.2c1.22-3.13,2.95-5.84,5.18-8.13s4.9-4.07,8.02-5.35
c3.12-1.28,6.57-1.92,10.36-1.92c2.54,0,4.93,0.29,7.17,0.88c2.24,0.59,4.3,1.42,6.17,2.5c1.87,1.07,3.55,2.38,5.04,3.92
c1.49,1.54,2.75,3.25,3.79,5.15c1.04,1.89,1.83,3.95,2.37,6.17C500.25,46.13,500.52,48.46,500.52,50.91z M490.99,50.91
c0-2.75-0.37-5.21-1.11-7.4s-1.79-4.04-3.15-5.56c-1.36-1.52-3.02-2.69-4.97-3.5c-1.95-0.81-4.15-1.21-6.6-1.21
c-2.45,0-4.65,0.4-6.6,1.21c-1.95,0.81-3.62,1.98-4.99,3.5c-1.38,1.52-2.43,3.38-3.17,5.56s-1.11,4.65-1.11,7.4
c0,2.75,0.37,5.21,1.11,7.4c0.74,2.18,1.8,4.03,3.17,5.54c1.37,1.51,3.04,2.67,4.99,3.48c1.95,0.81,4.15,1.21,6.6,1.21
c2.45,0,4.65-0.4,6.6-1.21c1.95-0.81,3.61-1.97,4.97-3.48s2.41-3.36,3.15-5.54C490.62,56.13,490.99,53.66,490.99,50.91z"/>
<path d="M538.19,34.97c-0.28,0.48-0.57,0.84-0.88,1.06c-0.31,0.22-0.7,0.33-1.16,0.33c-0.49,0-1.01-0.18-1.58-0.54
c-0.57-0.36-1.24-0.76-2.03-1.2c-0.79-0.44-1.7-0.84-2.75-1.2c-1.05-0.36-2.29-0.54-3.73-0.54c-1.29,0-2.42,0.16-3.4,0.47
c-0.97,0.31-1.78,0.75-2.44,1.3c-0.66,0.55-1.15,1.22-1.47,1.99c-0.32,0.77-0.49,1.62-0.49,2.55c0,1.18,0.33,2.16,0.99,2.95
c0.66,0.79,1.53,1.46,2.62,2.01c1.09,0.55,2.32,1.05,3.71,1.49c1.39,0.44,2.81,0.91,4.26,1.4c1.46,0.5,2.88,1.07,4.26,1.73
c1.39,0.66,2.62,1.49,3.71,2.5s1.96,2.23,2.62,3.69c0.66,1.46,0.99,3.22,0.99,5.3c0,2.26-0.39,4.38-1.16,6.36
c-0.77,1.98-1.91,3.7-3.4,5.16c-1.49,1.47-3.31,2.62-5.46,3.47c-2.15,0.84-4.61,1.26-7.38,1.26c-1.59,0-3.17-0.16-4.71-0.47
c-1.55-0.31-3.03-0.76-4.45-1.33s-2.75-1.27-4-2.08c-1.25-0.81-2.36-1.71-3.33-2.7l2.74-4.47c0.23-0.32,0.53-0.59,0.9-0.81
c0.37-0.22,0.76-0.33,1.18-0.33c0.58,0,1.2,0.24,1.87,0.71c0.67,0.47,1.47,1,2.39,1.58c0.92,0.58,2,1.1,3.24,1.58
c1.24,0.47,2.72,0.71,4.45,0.71c2.66,0,4.71-0.63,6.17-1.89s2.18-3.07,2.18-5.42c0-1.32-0.33-2.39-0.99-3.22
c-0.66-0.83-1.53-1.53-2.62-2.1c-1.09-0.57-2.32-1.04-3.71-1.44c-1.39-0.39-2.79-0.82-4.23-1.28s-2.84-1.02-4.23-1.66
c-1.39-0.65-2.62-1.49-3.71-2.53c-1.09-1.04-1.96-2.34-2.62-3.9c-0.66-1.56-0.99-3.48-0.99-5.77c0-1.82,0.36-3.6,1.09-5.34
c0.73-1.73,1.79-3.27,3.17-4.61c1.39-1.34,3.1-2.41,5.13-3.22c2.03-0.81,4.37-1.21,7-1.21c2.96,0,5.68,0.46,8.18,1.39
c2.5,0.92,4.62,2.22,6.38,3.88L538.19,34.97z"/>
<path d="M561.64,33.27v13.9h17.53v7.17h-17.53v14.17h22.25v7.45h-31.6V25.86h31.6v7.42H561.64z"/>
</g>
<g>
<path d="M651.88,75.96h-2.74c-0.32,0-0.59-0.09-0.8-0.26c-0.21-0.17-0.37-0.4-0.49-0.68l-5.75-14.24h-24.08l-5.75,14.24
c-0.09,0.25-0.25,0.47-0.48,0.66c-0.23,0.19-0.51,0.28-0.83,0.28h-2.7l20.06-49.03h3.5L651.88,75.96z M641.03,58.15l-9.98-24.78
c-0.35-0.81-0.68-1.78-1-2.91c-0.14,0.55-0.29,1.08-0.45,1.58c-0.16,0.5-0.33,0.95-0.52,1.37l-9.98,24.74H641.03z"/>
<path d="M696.61,75.96h-3.05c-0.39,0-0.72-0.06-0.99-0.19c-0.27-0.13-0.51-0.36-0.75-0.71L676.1,55.17
c-0.19-0.25-0.38-0.47-0.57-0.66c-0.2-0.18-0.42-0.33-0.66-0.43c-0.24-0.1-0.53-0.18-0.87-0.22c-0.33-0.05-0.75-0.07-1.23-0.07
h-6.41v22.18h-3.57V26.93h12.54c5.64,0,9.86,1.09,12.67,3.27s4.21,5.39,4.21,9.62c0,1.87-0.32,3.58-0.97,5.13
c-0.65,1.55-1.58,2.91-2.79,4.07c-1.21,1.17-2.68,2.12-4.4,2.86c-1.72,0.74-3.66,1.24-5.8,1.49c0.55,0.35,1.05,0.81,1.49,1.39
L696.61,75.96z M674.85,51.15c2.17,0,4.12-0.26,5.84-0.78c1.72-0.52,3.18-1.26,4.37-2.23c1.19-0.97,2.1-2.14,2.72-3.5
c0.62-1.36,0.94-2.9,0.94-4.61c0-3.49-1.14-6.08-3.41-7.76c-2.28-1.69-5.6-2.53-9.96-2.53h-8.97v21.41H674.85z"/>
<path d="M737.46,29.95h-17.22v46.02h-3.57V29.95h-17.33v-3.01h38.12V29.95z"/>
<path d="M752.05,75.96h-3.57V26.93h3.57V75.96z"/>
<path d="M793.11,32.89c-0.21,0.39-0.51,0.59-0.9,0.59c-0.3,0-0.69-0.21-1.16-0.64c-0.47-0.43-1.11-0.9-1.92-1.42
c-0.81-0.52-1.82-1-3.03-1.44c-1.21-0.44-2.71-0.66-4.49-0.66c-1.78,0-3.34,0.25-4.7,0.76c-1.35,0.51-2.48,1.2-3.4,2.08
c-0.91,0.88-1.61,1.89-2.08,3.05c-0.47,1.16-0.71,2.37-0.71,3.64c0,1.66,0.35,3.04,1.06,4.12c0.7,1.09,1.63,2.01,2.79,2.77
s2.47,1.4,3.93,1.92c1.47,0.52,2.97,1.03,4.52,1.54c1.55,0.51,3.05,1.07,4.52,1.68s2.78,1.38,3.93,2.3
c1.15,0.92,2.08,2.06,2.79,3.41c0.7,1.35,1.06,3.03,1.06,5.04c0,2.06-0.35,3.99-1.06,5.8c-0.71,1.81-1.73,3.39-3.07,4.73
c-1.34,1.34-2.98,2.4-4.92,3.17s-4.16,1.16-6.65,1.16c-3.24,0-6.02-0.57-8.35-1.72s-4.38-2.71-6.13-4.7l0.97-1.52
c0.28-0.35,0.6-0.52,0.97-0.52c0.21,0,0.47,0.14,0.8,0.42c0.32,0.28,0.72,0.62,1.18,1.02c0.46,0.41,1.02,0.84,1.66,1.32
s1.4,0.91,2.25,1.32c0.85,0.4,1.84,0.75,2.95,1.02c1.11,0.28,2.37,0.42,3.78,0.42c1.94,0,3.67-0.29,5.2-0.88s2.81-1.39,3.86-2.41
s1.85-2.22,2.41-3.62c0.55-1.4,0.83-2.89,0.83-4.49c0-1.73-0.35-3.16-1.06-4.28c-0.71-1.12-1.63-2.06-2.79-2.81
s-2.47-1.38-3.93-1.89s-2.97-1-4.52-1.49s-3.05-1.03-4.52-1.63c-1.47-0.6-2.78-1.37-3.93-2.3c-1.16-0.94-2.08-2.1-2.79-3.5
c-0.71-1.4-1.06-3.15-1.06-5.25c0-1.64,0.31-3.22,0.94-4.75c0.62-1.52,1.54-2.87,2.74-4.04c1.2-1.17,2.69-2.1,4.45-2.81
c1.77-0.7,3.78-1.06,6.05-1.06c2.54,0,4.82,0.4,6.84,1.21c2.02,0.81,3.86,2.04,5.53,3.71L793.11,32.89z"/>
<path d="M841.03,29.95h-17.22v46.02h-3.57V29.95h-17.33v-3.01h38.12V29.95z"/>
<path d="M844.46,73.3c0-0.44,0.08-0.85,0.24-1.25c0.16-0.39,0.39-0.73,0.68-1.02s0.62-0.52,1-0.69s0.8-0.26,1.26-0.26
c0.44,0,0.85,0.09,1.25,0.26c0.39,0.17,0.73,0.4,1.02,0.69s0.52,0.63,0.69,1.02c0.17,0.39,0.26,0.81,0.26,1.25
c0,0.46-0.09,0.88-0.26,1.26s-0.4,0.72-0.69,1s-0.63,0.51-1.02,0.68c-0.39,0.16-0.81,0.24-1.25,0.24c-0.9,0-1.66-0.31-2.27-0.92
C844.77,74.95,844.46,74.2,844.46,73.3z"/>
</g>
</svg>
</template>

<script>

export default {
    name: 'logo'
}

</script>

