<template>
  <div id="app">
    <navbar v-bind:class='{ dark: $route.name === "Enter"}'/>
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view/>
    </transition>
  </div>
</template>

<script>
import navbar from './core/navbar.vue'

export default {
  name: "App",
  components: {
    navbar
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Lato');

#app {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  min-height: 100vh;
}
/* 
h1, h2, h3, h4, h5 {
  font-family: 'Poppins', Helvetica, Arial, sans-serif; 
} */

.page {
  padding-top: 75px;
}

.poppins {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
